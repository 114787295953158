import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class PartiesApiService extends ApiService {
  constructor() {
    super();
  }

  getParties$(): Observable<PartyDto[]> {
    return this.get('/parties');
  }

  createParty$(name: string): Observable<PartyDto> {
    return this.put(`/parties/${name}`);
  }

  updateParty$(name: string, uniqId: string): Observable<PartyDto> {
    return this.post(`/parties/${uniqId}`, name);
  }

  getPartyById$(uniqId: string): Observable<PartyDto> {
    return this.get(`/parties/${uniqId}`);
  }

  deleteParty$(uniqueId: string): Observable<any> {
    return this.delete(`/parties/${uniqueId}`);
  }

  batchDeleteParties$(ids: string[]): Observable<any> {
    return this.post(`/parties/batch-delete`, ids);
  }
}

export interface PartyDto {
  id: number;
  name: string;
}

export const apiPartiesProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiParties.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiParties.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiParties.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiParties.scopes.write],
  },
];
